import styled from "styled-components";

import { Block } from "@/components/old/Block";

const Content = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const SocialItem = styled.a`
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const SocialItemImage = styled.img`
  height: 100%;
`;

type BlockSocialProps = {
  items: any[];
};

export function BlockSocial(props: BlockSocialProps) {
  return (
    <Block noPadding={true}>
      <Content>
        {props.items.map((item, index) => {
          return (
            <SocialItem key={index} href={item.content} target="_blank">
              <SocialItemImage src={item.imagem} />
            </SocialItem>
          );
        })}
      </Content>
    </Block>
  );
}
