import { useMemo } from "react";
import styled from "styled-components";
import { IonRouterLink, IonRippleEffect } from "@ionic/react";
import { Link as LinkPrimitive } from "react-router-dom";

import { getAppSlug } from "@/services/old/starter";
import { checkURLIsFile } from "@/utils/checkURLIsFile";
import { openFileInBrowser } from "@/utils/openFileInBrowser";
import { getFormatFile } from "@/utils/getFormatFile";

const Anchor = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
`;

const Link = styled(LinkPrimitive)`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
`;

const LinkFile = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
`;

interface BlockBannerProps {
  img_url: string;
  routerLink?: string;
  href?: string;
  type?: string;
  title?: string;
}

const Content = styled(IonRouterLink)`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  text-align: center;
  justify-content: end;
  align-items: center;
  position: relative;
  text-align: center;
  border-radius: 6px !important;
`;

export function BlockBanner(props: BlockBannerProps) {
  const isFile = useMemo(() => {
    if (props.href) {
      const isValidFile = checkURLIsFile(props.href);
      const format = getFormatFile(props.href);
      return isValidFile && format !== "php" && format !== "html";
    }
  }, [props.href]);

  function handleOpenFile() {
    if (props.href) {
      openFileInBrowser(props.href);
    }
  }

  return (
    <Content data-testid="blockitem-content" routerLink={props.routerLink}>
      <img
        style={{
          marginBottom: -8,
          borderRadius: "6px",
          display: "inline",
          verticalAlign: "baseline",
        }}
        src={props.img_url}
        alt=""
      />

      {props.href && props.type !== "banner" && props.type !== "banner_interno" && (
        <Anchor data-testid="blockitem-anchor" target="_blank" href={props.href} />
      )}

      {props.href && props.type === "banner" && (
        <Anchor data-testid="blockitem-anchor" target="_blank" href={props.href} />
      )}

      {props.href && props.type === "banner_interno" && (
        <>
          {!isFile && (
            <Link
              to={`/${getAppSlug()}/abrir?url=${encodeURIComponent(
                props.href,
              )}&title=${encodeURIComponent(props.title ?? "Mobby Cidade")}`}
            />
          )}

          {isFile && <LinkFile onClick={handleOpenFile} />}
        </>
      )}

      {props.routerLink && <IonRippleEffect type="bounded" />}
    </Content>
  );
}
