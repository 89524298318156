import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";

import { ReactPortal, Modal, Button } from "@/components";
import { Wallet } from "@/services/wallets";

interface ModalRenewText {
  isOpen: boolean;
  onCloseModal: () => void;
  data: Wallet | undefined;
}

export function RenewTextModal({ isOpen, onCloseModal, data }: ModalRenewText) {
  const { t } = useTranslation();
  return (
    <ReactPortal>
      <Modal showModal={isOpen} onClose={onCloseModal}>
        <div className="flex flex-col items-center gap-6">
          <Text color="slate-800" weight="medium">
            {t("wallets.Instruções para renovação")}
          </Text>
          <Text dangerouslySetInnerHTML={{ __html: data?.processDescription || "" }} />
          <Button full variant="secondary" onClick={onCloseModal}>
            {t("general.Fechar")}
          </Button>
        </div>
      </Modal>
    </ReactPortal>
  );
}
