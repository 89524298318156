import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { WarningCircle } from "@phosphor-icons/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { BlockSocial } from "@/pages/old/Home/components/BlockSocial";
import { BlockItemSlide } from "@/pages/old/Home/components/BlockItemSlide";
import { BlockBanner } from "@/components/old/BlockBanner";
import { BlockItem } from "@/components/old/BlockItem";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockProtocolWithMessage } from "@/pages/old/Home/components/BlockProtocolWithMessage";
import { VideoModal } from "@/pages/old/Home/components/VideoModal";

import { getContent } from "@/content/index";
import { STORAGE } from "@/data/storage";
import { getMainMenu } from "@/services/old/home";
import { isLogged, loadUserData } from "@/services/old/auth";
import { getMeusProtocolos } from "@/services/old/chat_provider";
import { getAppSlug, getInitialData } from "@/services/old/starter";
import { useConfig } from "@/hooks/useConfig";
import { useExistsSchedulingsCalled } from "./hooks/useSchedulingsCalled";
import { useAppId } from "@/store/useAppStore";

interface paramsPage {
  SLUG: string;
}

export function Home() {
  const CONTENT = getContent();

  const params: paramsPage = useParams();

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [dataContent, setDataContent] = useState([]);
  const [social, setSocial] = useState([]);
  const [protocolosNaoVistos, setProtocolosNaoVistos] = useState([]);
  const [appIcon, setAppIcon] = useState<string>();
  const [exibirFalaGestor, setExibirFalaGestor] = useState(false);

  const history = useHistory();

  const falaGestorHabilitado = useConfig({
    label: "habilitar.fala_gestor",
    slug: params.SLUG,
  });

  const intervalo = useConfig({
    label: "intervalo.fala_gestor",
    slug: params.SLUG,
  });

  const link = useConfig({
    label: "link.fala_gestor",
    slug: params.SLUG,
  })?.toString();

  const titulo = useConfig({
    label: "titulo.fala_gestor",
    slug: params.SLUG,
  })?.toString();

  const { data: existsSchedulingsCalled } = useExistsSchedulingsCalled({ appId });

  useEffect(() => {
    if (falaGestorHabilitado) {
      const ultimaExibicao = localStorage.getItem(
        `${params.SLUG}_ControlData_fala_gestor.ultima_exibicao`,
      );
      const ultimoLink = localStorage.getItem(`${params.SLUG}_ControlData_fala_gestor.ultimo_link`);
      const intervaloMiliSegundos = (Number(intervalo) ?? 0) * 60 * 60 * 1000;
      const cond1 = link && Date.now() - Number(ultimaExibicao ?? 0) >= intervaloMiliSegundos;
      const cond2 = link && link !== ultimoLink;

      if (cond1 || cond2) {
        setExibirFalaGestor(true);
        localStorage.setItem(
          `${params.SLUG}_ControlData_fala_gestor.ultima_exibicao`,
          Date.now().toString(),
        );
        localStorage.setItem(`${params.SLUG}_ControlData_fala_gestor.ultimo_link`, link);
      }
    }
  }, [falaGestorHabilitado, intervalo, link]);

  useEffect(() => {
    getInitialData(getAppSlug()).then((res) => {
      setSocial(res.data.redes_sociais);
      if (res.data.icone) {
        setAppIcon(res.data.icone);
      }
      setIsLoadingInitial(false);
    });
    getMainMenu(params.SLUG).then((res) => {
      setDataContent(res.data);
      setIsLoading(false);
    });

    if (isLogged()) {
      getMeusProtocolos(params.SLUG).then((data) => {
        setProtocolosNaoVistos(data.data);
      });

      loadUserData(params.SLUG).then((res: any) => {
        if (res) {
          if (
            !res.data.versao_termo_uso ||
            res.data.versao_termo_uso !== localStorage.getItem(STORAGE.TERM_USE_VERSION.KEY)
          ) {
            history.push("/" + getAppSlug() + "/user-terms");
          }
        }
      });
    }
  }, [prompt]);

  if (isLoading && isLoadingInitial) {
    return (
      <PageTemplate name={CONTENT.APP_NAME}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate
      name={CONTENT.APP_NAME}
      headerIcon={appIcon}
      backgroundColor="#fff"
      hideHeaderUserButton={false}
    >
      {/* <BlockStoreEvaluation/> */}

      <VideoModal
        isOpen={exibirFalaGestor}
        close={() => setExibirFalaGestor(false)}
        link={link}
        title={titulo}
      />
      <div>
        <IonGrid>
          {social.length > 0 && (
            <IonRow>
              <IonCol>
                <BlockSocial items={social} />
              </IonCol>
            </IonRow>
          )}

          {existsSchedulingsCalled ? (
            <IonRow>
              <IonCol>
                <Link
                  to={`/${params.SLUG}/meus-agendamentos`}
                  className="mt-1 flex items-center gap-2 rounded-md bg-orange-50 px-3 py-2"
                >
                  <WarningCircle
                    size={20}
                    className="flex-shrink-0 text-orange-600"
                    weight="fill"
                  />

                  <Text size="sm" className="text-orange-600">
                    Atenção!{" "}
                    <strong className="font-semibold">
                      Chegou sua vez de realizar o agendamento
                    </strong>{" "}
                    , <span className="underline">clique aqui</span> para concluir o processo.
                  </Text>
                </Link>
              </IonCol>
            </IonRow>
          ) : null}

          {protocolosNaoVistos.length > 0 && (
            <IonRow>
              <IonCol>
                <BlockProtocolWithMessage items={protocolosNaoVistos} />
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            {dataContent.map((item: any, index: number) => {
              if (item.type === "news" && item.highlight) {
                return (
                  <IonCol key={index} size={`${item.size}`}>
                    <BlockItemSlide
                      compact={item.size !== 12}
                      title={item.title}
                      type={item.type}
                      customImage={item.imagem}
                      SLUG={params.SLUG}
                      routerLink={"/" + getAppSlug() + item.url}
                    />
                  </IonCol>
                );
              } else {
                return (
                  <IonCol key={index} size={item.size}>
                    {!["link_interno", "link"].includes(item.type) && (
                      <BlockBanner
                        title={item.title}
                        type={item.type}
                        img_url={item.imagem}
                        href={`${item.url}`.startsWith("/") ? undefined : item.url}
                        routerLink={
                          `${item.url}`.startsWith("/") ? `/${getAppSlug()}${item.url}` : undefined
                        }
                      />
                    )}
                    {["link_interno", "link"].includes(item.type) && (
                      <BlockItem
                        title={item.title}
                        customImage={item.imagem}
                        type={item.type}
                        href={`${item.url}`.startsWith("/") ? undefined : item.url}
                        routerLink={
                          `${item.url}`.startsWith("/") ? `/${getAppSlug()}${item.url}` : undefined
                        }
                      />
                    )}
                  </IonCol>
                );
              }
            })}
          </IonRow>
        </IonGrid>
      </div>
    </PageTemplate>
  );
}
