import styled from "styled-components";
import { useState, useEffect } from "react";
import { Block } from "@/components/old/Block";
import { BlockItem } from "@/components/old/BlockItem";
import { BlockItemImage } from "@/components/old/BlockItemImage";
import { BlockItemImageNews } from "@/pages/old/Home/components/BlockItemImageNews";
import { getContent } from "@/content/index";
import { getAppSlug } from "@/services/old/starter";
import { getPreviewNews } from "@/services/old/news";
import { useWindowDimensions } from "@/hooks/useWindowDimensions";
import { Button } from "@astrolabe-ui/react";
import { IonRouterLink } from "@ionic/react";
import { CaretRight } from "@phosphor-icons/react";

const DotDiv = styled.div`
  display: flex;
  flex-grow: auto;
  vertical-align: middle;
  align-items: center;
`;

const DotItem = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 15px;
`;
const DotCurrentItem = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 15px;
`;
interface BlockItemProps {
  title?: string;
  icon?: any;
  compact?: boolean;
  customImage?: string;
  routerLink?: string;
  href?: string;
  type: string;
  SLUG: string;
}

export function BlockItemSlide(props: BlockItemProps) {
  const CONTENT = getContent();

  const [isLoading, setIsLoadding] = useState(true);
  const [dataArray, setDataArray] = useState<any[]>([]);
  const [currentPos, setCurrentPos] = useState(0);
  const [reload, setReload] = useState(true);

  const { width } = useWindowDimensions();

  useEffect(() => {
    let isMounted = true;

    if (isLoading) {
      getPreviewNews(props.SLUG)
        .then((res) => {
          setDataArray(res.data.data);

          setIsLoadding(false);
          setReload(!reload);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (!isLoading || dataArray.length > 0) {
      setTimeout(() => {
        if (isMounted) {
          setCurrentPos(currentPos < dataArray.length - 1 ? currentPos + 1 : 0);
          setReload(!reload);
        }
      }, 4000);
    }
    return () => {
      isMounted = false;
    }; // use cleanup to toggle value, if unmounted
  }, [reload]);

  if (isLoading || dataArray.length === 0 || width < 200) {
    return (
      <BlockItem
        title={props.title}
        customImage={props.customImage}
        routerLink={props.routerLink}
      />
    );
  } else {
    if (props.compact || width < 330) {
      return (
        <Block noPadding={true}>
          <BlockItemImage
            routerLink={"/" + getAppSlug() + "/news/" + dataArray[currentPos].id}
            imageUrl={dataArray[currentPos].imagem}
            title={dataArray[currentPos].titulo || ""}
          />
          <IonRouterLink routerLink={props.routerLink}>
            <Button variant="tertiary" full>
              {CONTENT.NEWS.SEE_ALL} &#62;
            </Button>
          </IonRouterLink>
        </Block>
      );
    }
    return (
      <Block noPadding={true}>
        <BlockItemImageNews
          routerLink={"/" + getAppSlug() + "/news/" + dataArray[currentPos].id}
          imageUrl={dataArray[currentPos].imagem}
          title={dataArray[currentPos].titulo || ""}
          category={dataArray[currentPos].categoria?.nome}
          categories={dataArray[currentPos].multi_categorias}
        />
        <div style={{ display: "flex", borderTop: "1px solid rgb(230, 230, 230)" }}>
          <div
            style={{ width: "50%", paddingLeft: "8px", display: "flex", justifyItems: "center" }}
          >
            <DotDiv>
              {dataArray.map((_, index: number) => {
                if (index > 4) {
                  return <></>;
                } else if (index === currentPos) {
                  return (
                    <DotCurrentItem
                      className="bg-primary-500"
                      key={index}
                      onClick={() => {
                        setCurrentPos(index);
                      }}
                    />
                  );
                } else {
                  return (
                    <DotItem
                      className="bg-slate-200"
                      key={index}
                      onClick={() => {
                        setCurrentPos(index);
                      }}
                    />
                  );
                }
              })}
            </DotDiv>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row-reverse",
              marginRight: "0px",
            }}
          >
            <IonRouterLink routerLink={props.routerLink}>
              <Button
                variant="tertiary"
                full
                className="[&>svg]:h-4 [&>svg]:w-4"
                rightIcon={<CaretRight />}
              >
                {CONTENT.NEWS.SEE_ALL}
              </Button>
            </IonRouterLink>
          </div>
        </div>
      </Block>
    );
  }
}
