import { IonButton } from "@ionic/react";
import { Link, useParams } from "react-router-dom";

import { Block } from "@/components/old/Block";
import { getAppSlug } from "@/services/old/starter";
import { useConfig } from "@/hooks/useConfig";

type BlockProtocolWithMessageProps = {
  items: Array<{
    protocolo: number;
  }>;
};

const pagesProtcolsByType: Record<number, string> = {
  1: "protocolo",
  2: "protocol",
  3: "protocols",
};

export function BlockProtocolWithMessage(props: BlockProtocolWithMessageProps) {
  const params = useParams<{ SLUG: string }>();

  const typeProtocols = useConfig({
    label: "page.protocols",
    slug: params.SLUG,
  });

  const urlProtocols = pagesProtcolsByType[Number(typeProtocols)];

  return (
    <Block noPadding={true}>
      {props.items.map((item) => {
        return (
          <Link key={item.protocolo} to={`/${getAppSlug()}/${urlProtocols}/${item.protocolo}`}>
            {" "}
            <IonButton expand="full" className="text-xs xs:text-sm">
              Nova mensagem no protocolo: {item.protocolo}
            </IonButton>
          </Link>
        );
      })}
    </Block>
  );
}
