import { useEffect, useRef, useState } from "react";
import { IonModal } from "@ionic/react";
import { Box, Text } from "@astrolabe-ui/react";
import { CaretRight } from "@phosphor-icons/react";

import { SelectItem, ReactPortal } from "@/components";
import { cn } from "@/lib/utils";

interface SelectItemsProps {
  options: Array<{
    value: string;
    label: string;
  }>;
  selected?: string;
  title: string;
  hint: string | null;
  error?: string;
  disabled?: boolean;
  onChange: (option: string) => void;
}

export function SelectItems({
  options,
  selected,
  disabled = false,
  title,
  hint,
  error,
  onChange,
}: SelectItemsProps) {
  const modal = useRef<HTMLIonModalElement>(null);

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    const item = options.find((item) => item.label === selected);
    setSelectedOption(item?.value ?? null);
  }, [options, selected]);

  function handleOnSelected(item: string) {
    setSelectedOption(item);

    const label = options.find((option) => option.value === item)?.label;

    if (label) {
      onChange(label);
    }
  }

  function handleOpenModal() {
    if (!disabled) {
      modal.current?.present();
    }
  }

  function handleCloseModal() {
    modal.current?.dismiss();
  }

  return (
    <>
      <div>
        <Box
          px={4}
          rounded="md"
          onClick={handleOpenModal}
          className={cn("flex h-[53px] items-center justify-between border-thin", {
            "cursor-not-allowed bg-slate-100": disabled,
            "border-red-500": !!error,
          })}
        >
          {selected ? (
            <div>
              <Text size="xs">{title}</Text>
              <Text size="sm" color="slate-700">
                {selected}
              </Text>
            </div>
          ) : (
            <Text size="xs">{title}</Text>
          )}

          <CaretRight
            size={14}
            weight="bold"
            className={cn("text-slate-600", {
              "text-slate-400": disabled,
            })}
          />
        </Box>

        {error ? (
          <Text size="xs" color="red-500" className="mt-1.5">
            {error}
          </Text>
        ) : null}

        {!error && !!hint && (
          <Text asChild size="xs" color="slate-400" className="ml-1 mt-1">
            <p>{hint}</p>
          </Text>
        )}
      </div>



      <ReactPortal>
        <IonModal ref={modal}>
          <SelectItem
            items={options}
            selectedItem={selectedOption}
            title={title}
            onCancel={handleCloseModal}
            onChange={(value) => {
              handleOnSelected(value);
              handleCloseModal();
            }}
          />
        </IonModal>
      </ReactPortal>
    </>
  );
}
