import { IonSpinner } from "@ionic/react";
import { useState } from "react";
import { FiX } from "react-icons/fi";

import { CloseButton, Content, Modal } from "./styles";

type VideoModalProps = {
  isOpen: boolean;
  close: () => void;
  link?: string;
  title?: string;
};

export function VideoModal({ isOpen, close, link, title }: VideoModalProps) {
  const [loading, setLoading] = useState(true);

  return (
    <Modal isOpen={isOpen}>
      <Content>
        <CloseButton onClick={close}>
          <FiX size={20} color={"#000"} />
        </CloseButton>
        <div className="title">{title}</div>
        <div className="load">{loading && <IonSpinner color="dark" name="crescent" />}</div>
        {link?.match(/(jpeg|jpg|gif|heic|bmp|svg|png)$/) ? (
          <img src={link} alt={title} />
        ) : (
          <iframe
            src={`https://www.youtube.com/embed/${link?.slice(link?.search(/v=/) + 2)}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Abertura"
            onLoad={() => setLoading(false)}
            loading="lazy"
          />
        )}
      </Content>
    </Modal>
  );
}
