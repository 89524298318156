import { useConfig } from "@/hooks/useConfig";
import { Iframe } from "@/templates/Iframe";

export function HelperCenter() {
  const url = useConfig({ label: "external.suport.help" });

  if (!url) {
    return null;
  }
  return (
    <Iframe
      name="Central de ajuda"
      src={url.toString()}
      closeButton={true}
      showCloseModal={false}
      closeTitle="Tem certeza que quer fazer isso?"
      closeText="Ao fechar a páginar, você não poderá retomar o seu atendimento e precisará iniciar um novo."
    />
  );
}
