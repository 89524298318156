import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

import { IDisplayAddress, IFormAddress } from ".";

export interface IPopMessage {
  title: string;
  subtitle?: string;
}

const MapContext = createContext({
  center: {
    lat: 0,
    lng: 0,
  },
  popMessage: undefined,
} as {
  center: {
    lat: number;
    lng: number;
  };
  popMessage?: IPopMessage;
  setCenter: Dispatch<
    SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  textLocation: IDisplayAddress | undefined;
  setTextLocation: Dispatch<SetStateAction<IDisplayAddress | undefined>>;
  dataLocation: IFormAddress;
  setDataLocation: Dispatch<SetStateAction<IFormAddress>>;
  setSendByUser: Dispatch<SetStateAction<boolean>>;
  sendByUser: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
});

export const useMapContext = () => useContext(MapContext);

export const MapProvider = ({
  popMessage,
  setTextLocation,
  textLocation,
  dataLocation,
  setDataLocation,
  sendByUser,
  setSendByUser,
  loading,
  setLoading,
  children,
}: {
  popMessage?: IPopMessage;
  textLocation: IDisplayAddress | undefined;
  setTextLocation: Dispatch<SetStateAction<IDisplayAddress | undefined>>;
  dataLocation: IFormAddress;
  setDataLocation: Dispatch<SetStateAction<IFormAddress>>;
  setSendByUser: Dispatch<SetStateAction<boolean>>;
  sendByUser: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  children: ReactNode;
}) => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  return (
    <MapContext.Provider
      value={{
        center,
        popMessage,
        setCenter,
        setTextLocation,
        textLocation,
        dataLocation,
        setDataLocation,
        sendByUser,
        setSendByUser,
        loading,
        setLoading,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
