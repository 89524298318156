import { http } from "@/lib/axios";
import { getAccessToken } from "./auth";
import { getAppId } from "./provider";

export async function getMeusProtocolos(SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  return http.get("v2/" + APP_ID + "/usuarios/mensagensNaoLidas", headers);
}
