import { http } from "@/lib/axios";

type ExistsScheudlingCalledResponse = Array<{
  status: string;
}>;

type ExistsSchedulingCalledParams = {
  appId: number;
};

export async function existsSchedulingCalled({ appId }: ExistsSchedulingCalledParams) {
  try {
    const response = await http.get<ExistsScheudlingCalledResponse>(
      `v3/${appId}/mobile/meus_cadastros`,
    );

    return !!response.data.filter((item) => item.status === "chamado").length;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
