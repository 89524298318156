import { useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";

import { RenewTextModal } from "./renew-text-modal";
import { Wallet } from "@/services/wallets";
import { useUserStore } from "@/store/useUserStore";

type NoticesProps = {
  wallet: Wallet;
};

export function Notices({ wallet }: NoticesProps) {
  const { t } = useTranslation();
  const userId = useUserStore((state) => state.user?.id);

  const [isOpenRenewText, setIsOpenRenewText] = useState(false);

  function onCloseModal() {
    setIsOpenRenewText(false);
  }

  const validadeDate = dayjs(wallet?.validity);
  const daysUntilValid = validadeDate.diff(dayjs(), "day");
  const isRenewalDue =
    wallet?.comunicationDays != null ? daysUntilValid <= wallet.comunicationDays : false;

  const renderDay =
    daysUntilValid === 0 ? "hoje" : daysUntilValid === 1 ? "amanhã" : `em ${daysUntilValid} dias`;

  if (
    wallet?.status === "suspenso" ||
    (userId === wallet.userId && !wallet.expired && isRenewalDue) ||
    (userId === wallet?.userId && wallet?.expired)
  ) {
    return (
      <div className="flex flex-1 flex-col gap-3">
        {wallet?.status === "suspenso" ? (
          <div className="flex w-full flex-col gap-0.5 rounded-sm bg-orange-50 p-4">
            <Text asChild size="sm" weight="medium" color="orange-600">
              <strong>{t("wallets.Justificativa da suspensão")}</strong>
            </Text>
            <Text color="slate-800">{wallet.justification}</Text>
          </div>
        ) : null}

        {userId === wallet.userId && !wallet.expired && isRenewalDue ? (
          <div className="flex w-full flex-col gap-0.5 rounded-sm bg-orange-50 p-4">
            <Text asChild size="sm" weight="medium" color="orange-600">
              <strong>{t("wallets.Instruções para renovação")}</strong>
            </Text>
            <Text color="slate-800">
              {t("wallets.Sua carteira vence")} <strong>{renderDay}.</strong>{" "}
              {t(
                "wallets.Para mais informações sobre o que deve ser feito para renovar a carteira, ",
              )}
              <button onClick={() => setIsOpenRenewText(true)} className="underline">
                {t("clique aqui.")}
              </button>
            </Text>
          </div>
        ) : null}

        {userId === wallet?.userId && wallet?.expired ? (
          <div className="flex w-full flex-col gap-0.5 rounded-sm bg-red-50 p-4">
            <Text asChild size="sm" weight="medium" color="red-500">
              <strong>{t("wallets.Carteira vencida")}</strong>
            </Text>

            <Text color="slate-800">
              {wallet.processDescription ? (
                <>
                  {t(
                    "wallets.Para mais informações sobre o que deve ser feito para renovar a carteira, ",
                  )}
                  <button onClick={() => setIsOpenRenewText(true)} className="underline">
                    {t("clique aqui.")}
                  </button>
                </>
              ) : (
                t(
                  "wallets.Validade do documento digital expirada. Entre em contato com o emissor para mais informações.",
                )
              )}
            </Text>
          </div>
        ) : null}

        <RenewTextModal data={wallet} isOpen={isOpenRenewText} onCloseModal={onCloseModal} />
      </div>
    );
  }

  return null;
}
