import { http } from "@/lib/axios";
import { getAppId } from "./provider";

export async function getNews(page: number, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/${APP_ID}/noticias?page=${page}&paginate=1`);
}

export async function getPreviewNews(SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v3/${APP_ID}/noticias?paginate=1&per_page=5`);
}

export async function getNewsPost(postId: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/${APP_ID}/noticias/${postId}`);
}
