import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { menuController } from "@ionic/core/components";
import { Text } from "@astrolabe-ui/react";
import {
  Cards,
  Files,
  SignOut,
  Storefront,
  User,
  UserFocus,
  UserPlus,
  CalendarBlank,
} from "@phosphor-icons/react";

import { NavLink } from "./NavLink";
import { NavButton } from "./NavButton";
import { ModalLogout } from "./ModalLogout";
import { useAppSlug } from "@/store/useAppStore";
import { useConfig } from "@/hooks/useConfig";
import { useAuthStore, useUserToken } from "@/store/useAuthStore";
import { useHolder, useUserStore } from "@/store/useUserStore";
import { logout } from "@/services/old/auth";
import { getSellerIdLoged } from "@/services/old/feirinha";

const pagesProtcolsByType: Record<number, string> = {
  1: "my-services",
  2: "my-protocols",
  3: "protocols",
};

export function LoggedContent() {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const isLogged = !!useUserToken();
  const user = useUserStore((state) => state.user);

  if (!isLogged) {
    throw new Error("Not user authenticated");
  }

  const [showModalLogout, setShowModalLogout] = useState(false);

  const slug = useAppSlug();
  const holder = useHolder();
  const updateToken = useAuthStore((state) => state.updateToken);

  const typeProtocols = useConfig({
    label: "page.protocols",
  });

  const showHolderLink = useConfig({
    label: "habilitar.titularidade",
  });

  const showWallets = useConfig({
    label: "habilitar.carteira",
  });

  const enabledMySchedulings = useConfig({
    label: "habilitar.novoagendamento",
  });

  const isSeller = getSellerIdLoged();

  function handleLogout() {
    setShowModalLogout(true);
  }

  function logoutUser() {
    queryClient.clear();

    updateToken(null);

    logout();

    history.push(`/${slug}/auth`);

    menuController.close("main");
  }

  function handleCloseModalLogout() {
    setShowModalLogout(false);
  }

  const urlProtocols = useMemo(() => pagesProtcolsByType[Number(typeProtocols)], [typeProtocols]);

  return (
    <div className="flex-1 space-y-3 pt-[calc(var(--safe-area-inset-top)+1.5rem)]">
      <div className="flex items-center gap-2 px-4">
        {user?.imageUrl ? (
          <img src={user.imageUrl} alt="" className="h-10 w-10 rounded-full object-cover" />
        ) : null}

        <div className="overflow-hidden">
          <Text size="md" color="slate-700" weight="medium">
            {user?.name.split(" ")[0]}
          </Text>
          <Text className="truncate">{user?.email}</Text>
        </div>
      </div>

      <div className="flex-1 space-y-[2px]">
        <NavLink path={`/${slug}/my-profile`} title={t("menu.Meu perfil")} icon={User} />

        {holder ? (
          <NavLink path={`/${slug}/holder`} title={t("menu.Titularidade")} icon={UserFocus} />
        ) : null}

        {!holder && showHolderLink ? (
          <NavLink path={`/${slug}/link`} title={t("menu.Vincular-se a titular")} icon={UserPlus} />
        ) : null}

        {isSeller > 0 ? (
          <NavLink
            path={`/${slug}/abrir-vendedor`}
            title={t("menu.Perfil vendedor")}
            icon={Storefront}
          />
        ) : null}

        <NavLink path={`/${slug}/${urlProtocols}`} title={t("menu.Meus protocolos")} icon={Files} />

        {enabledMySchedulings ? (
          <NavLink
            path={`/${slug}/meus-agendamentos`}
            title={t("menu.Meus agendamentos")}
            icon={CalendarBlank}
          />
        ) : null}

        {showWallets && holder ? (
          <NavLink path={`/${slug}/carteiras`} title={t("menu.Minhas carteiras")} icon={Cards} />
        ) : null}

        <NavButton onClick={handleLogout} title={t("menu.Sair da conta")} icon={SignOut} />
      </div>

      <ModalLogout
        isOpen={showModalLogout}
        onCloseModal={handleCloseModalLogout}
        onConfirmation={logoutUser}
      />
    </div>
  );
}
