import { existsSchedulingCalled } from "@/services/schedulings";
import { useQuery } from "@tanstack/react-query";

type UseExistsSchedulingsCalledParams = {
  appId: number;
};

export function useExistsSchedulingsCalled({ appId }: UseExistsSchedulingsCalledParams) {
  return useQuery({
    queryKey: ["exists_schedulings_called"],
    queryFn: () => existsSchedulingCalled({ appId }),
  });
}
