import { history } from "@/router/Router";
import { useConfigStore } from "@/store/useConfigStore";
import OneSignal from "onesignal-cordova-plugin";

interface OneSignalInitProps {
  slug: string;
  onesignalAppId: string;
  userId?: number;
}

type NotificationResponse = {
  id: number;
  tipo: string;
  link?: string;
  hash_verif?: string;
  titular_id?: string;
  carteira_id?: string;
};

export function OneSignalInit({ onesignalAppId, userId, slug }: OneSignalInitProps) {
  OneSignal.setAppId(onesignalAppId);

  if (userId) {
    OneSignal.setExternalUserId(String(userId));
    OneSignal.sendTag("user_id", String(userId));
  }

  OneSignal.setNotificationOpenedHandler((data) => {
    if (data.notification.additionalData) {
      notificationOpenedCallback({
        notification: data.notification.additionalData as NotificationResponse,
        slug,
      });
    }
  });

  OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
    console.log(`User accepted notifications: ${accepted}`);
  });
}

const pagesProtcolsByType: Record<number, string> = {
  1: "protocolo",
  2: "protocol",
  3: "protocols",
};

interface NotificationOpenedCallbackParams {
  notification: NotificationResponse;
  slug: string;
}

function notificationOpenedCallback({ notification, slug }: NotificationOpenedCallbackParams) {
  try {
    const {
      actions: { getConfig },
    } = useConfigStore.getState();
    const typeProtocolsConfig = getConfig("page.protocols");
    const typeProtocols = Number(typeProtocolsConfig?.value);

    const urlsMap: Record<string, string> = {
      noticia: `news/${notification.id}`,
      produtoFeirinha: `abrir-produto/${notification.id}`,
      agendamento: "meus-agendamentos",
      carteira: `carteiras/${notification.carteira_id}?idTitular=${notification.titular_id}&hashCarteira=${notification.hash_verif}`,
      mensagemProtocolo: typeProtocols
        ? `${pagesProtcolsByType[typeProtocols]}/${notification.id}`
        : `protocols/${notification.id}`,
    };

    let url = "";

    if (notification.tipo === "programada" && notification.link) {
      url = notification.link;
    } else {
      const path = urlsMap[notification.tipo];
      url = `/${slug}/${path ?? "home"}`;
    }

    history.replace(url);
  } catch (err) {
    console.error(err);
  }
}
