import styled from "styled-components";
import { IonRouterLink } from "@ionic/react";
import { Box, Text } from "@astrolabe-ui/react";

interface BlockItemImageProps {
  title?: string;
  discription?: string;
  href?: string;
  openNewWindow?: boolean;
  routerLink?: string;
  imageUrl?: string;
  custom_height?: string;
  noShadow?: boolean;
  openExternal?: boolean;
  linkService?: string;
  category?: string;
  categories?: Array<{ nome: string; id: number }>;
}

const Discription = styled.h6`
  margin: 5px;
  font-weight: 500;
`;

export function BlockItemImageNews(props: BlockItemImageProps) {
  return (
    <IonRouterLink routerLink={props.routerLink}>
      <Box asChild px={3} py={3} className="flex flex-col gap-2 border-none hover:cursor-pointer">
        <article>
          <div className="flex justify-between gap-4">
            <div className="flex flex-1 flex-col gap-1 overflow-hidden">
              {props.categories?.length ? (
                <div className="flex">
                  <span className="w-fit rounded-xs bg-primary-50 px-3 py-1 font-sans text-xs/normal font-medium text-primary-500">
                    {props.categories[0].nome}
                  </span>
                  {props.categories.length > 1 && (
                    <span className="ml-1 w-fit rounded-xs bg-primary-50 px-3 py-1 font-sans text-xs/normal font-medium text-primary-500">
                      + {props.categories.length - 1}
                    </span>
                  )}
                </div>
              ) : (
                <span className="w-fit rounded-xs bg-primary-50 px-3 py-1 font-sans text-xs/normal font-medium text-primary-500">
                  {props.category}
                </span>
              )}

              <Text weight="medium" color="slate-700" className="line-clamp-3">
                {props.title || ""}
              </Text>
            </div>
            {props.discription && <Discription>{props.discription}</Discription>}
            <figure
              role="presentation"
              className="aspect-[13/9] w-[120px] shrink-0 overflow-hidden rounded-xs"
            >
              <img src={props.imageUrl} alt="" className="h-full w-full object-cover" />
            </figure>
          </div>
        </article>
      </Box>
    </IonRouterLink>
  );
}
