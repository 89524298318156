import { http } from "@/lib/axios";

import { getAccessToken } from "./auth";
import { getAppId } from "./provider";

export async function getMainMenu(SLUG: string) {
  const token = getAccessToken();

  const APP_ID = await getAppId(SLUG);

  return http.get("v2/" + APP_ID + "/mainMenu", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
