import { http } from "@/lib/axios";
import { formatCpf } from "@/utils/formats";
import { hexToRgb } from "@/utils/hexToRGB";
import { Holder, Wallet } from "./type";

export type FieldResponse = {
  id: number;
  nome: string;
  campos_carteiras_respostas: Array<{
    conteudo: string;
  }>;
};

function formatDate(value: string) {
  return new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
    new Date(value.replace(/-/g, "/")),
  );
}

function getFieldsFormatted({
  fields,
  holder,
  validity,
}: {
  fields: FieldResponse[];
  holder: Holder;
  validity: string | null;
}) {
  const fieldsFormatted = [];

  if (validity) {
    fieldsFormatted.push({
      id: Math.floor(Math.random() * 100000),
      label: "Validade",
      value: formatDate(validity),
    });
  }

  fields.forEach((field) => {
    if (field.campos_carteiras_respostas.length > 0) {
      fieldsFormatted.push({
        id: field.id,
        label: field.nome,
        value: field.campos_carteiras_respostas[0].conteudo,
      });
    }
  });

  fieldsFormatted.push({
    id: Math.floor(Math.random() * 100000),
    label: "Nome",
    value: holder.name,
  });

  fieldsFormatted.push({
    id: Math.floor(Math.random() * 100000),
    label: "CPF",
    value: formatCpf(holder.cpf),
  });

  fieldsFormatted.push({
    id: Math.floor(Math.random() * 100000),
    label: "Data de nascimento",
    value: formatDate(holder.dateOfBirth),
  });

  return fieldsFormatted;
}

interface GetWalletProps {
  appId: number;
  walletId: number;
  holderId: number;
  hashWallet: string;
}

interface GetWalletResponse {
  id: number;
  nome: string;
  logo: string | null;
  orgao_emissor: string;
  background: string | null;
  back_background?: string | null;
  cor_primaria: string;
  cor_secundaria: string;
  descricao_processo: string | null;
  qtd_dias_comunicacao: number | null;
  campos_carteiras: Array<FieldResponse>;
  titulares_carteiras: Array<{
    validade: string | null;
    status: string;
    justificativa: string | null;
    vencido: boolean;
    titular: {
      id: number;
      nome: string;
      nome_social: string | null;
      cpf: string;
      data_nascimento: string;
      foto: string;
      user_id: number;
    };
  }>;
}

export async function getWallet({
  appId,
  walletId,
  holderId,
  hashWallet,
}: GetWalletProps): Promise<Wallet> {
  const response = await http.get<GetWalletResponse>(
    `v3/${appId}/carteiras/${walletId}/titular_carteira?id_titular=${holderId}&hash_carteira=${hashWallet}`,
  );

  const holderWalletResponse = response.data.titulares_carteiras[0];

  const holderResponse = holderWalletResponse.titular;

  const holder = {
    id: holderResponse.id,
    name: holderResponse.nome_social ?? holderResponse.nome,
    cpf: holderResponse.cpf,
    dateOfBirth: holderResponse.data_nascimento,
    imageUrl: holderResponse.foto,
  };

  const walletResponse = response.data;

  const wallet = {
    id: walletResponse.id,
    name: walletResponse.nome,
    issuingBody: walletResponse.orgao_emissor,
    imageLogo: walletResponse.logo,
    imageBackground: walletResponse.background,
    imageBackBackground: walletResponse?.back_background ?? null,
    colorPrimary: walletResponse.cor_primaria,
    colorSecondary: walletResponse.cor_secundaria,
    processDescription: walletResponse.descricao_processo,
    comunicationDays: walletResponse.qtd_dias_comunicacao,
    status: holderWalletResponse.status,
    validity: holderWalletResponse.validade,
    justification: holderWalletResponse.justificativa,
    userId: holderWalletResponse?.titular.user_id,
    expired: !!holderWalletResponse.vencido,
    holder,
    fields: getFieldsFormatted({
      fields: walletResponse.campos_carteiras,
      validity: holderWalletResponse.validade,
      holder,
    }),
  };

  if (wallet?.colorPrimary && wallet?.colorSecondary) {
    document.documentElement.style.setProperty("--walletPrimary", hexToRgb(wallet.colorPrimary));
    document.documentElement.style.setProperty("--walletSecondary", wallet.colorSecondary);
  }

  return wallet;
}
