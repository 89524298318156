import { Capacitor } from "@capacitor/core";
import { Redirect } from "react-router-dom";

import { useUserToken } from "@/store/useAuthStore";
import logoMobbyImg from "@/assets/images/mobby_logo.svg";

export function LinkToSite() {
  const isLogged = !!useUserToken();

  if (Capacitor.isNativePlatform()) {
    const slug = localStorage.getItem("slug");

    let url = `/${slug}`;

    if (!isLogged) {
      url += "/auth";
    }

    return <Redirect to={url} />;
  }

  return (
    <main className="flex min-h-screen flex-col items-center justify-between bg-white py-8">
      <img src={logoMobbyImg} alt="" className="h-10" />

      <h1 className="text-center text-lg font-medium text-slate-900">
        Conheça mais das soluções Mobby!
      </h1>

      <a
        href="https://mobbyapps.com"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full max-w-xs rounded-md bg-[#202248] px-8 py-3 text-center font-medium text-white"
      >
        Ir para site
      </a>
    </main>
  );
}
