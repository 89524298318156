import { IonModal } from "@ionic/react";
import styled from "styled-components";

export const Modal = styled(IonModal)`
  --background: #ffffff;
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 8px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  padding: 10px;
`;

export const Content = styled.div`
  width: 100%;
  max-height: 95vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 35px 24px 24px 24px;

  iframe {
    border: none;
    border-radius: 4px;
    width: 100%;
    aspect-ratio: 16/9;
    z-index: 666;
  }

  img {
    border-radius: 4px;
    width: 100%;
    max-height: 95vh;
    z-index: 666;
  }

  .title {
    margin-bottom: 32px;
    color: black;
    font-size: 24px;
  }

  .load {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% 50%);
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  display: flex;
  place-items: center;
`;
