import { Capacitor } from "@capacitor/core";
import { useQuery } from "@tanstack/react-query";
import { setPrimaryColor } from "@astrolabe-ui/react";
import axios from "axios";

import { getDataApp } from "@/services/startApp/getDataStartApp";
import { getAppIdentifier } from "@/services/startApp/getAppIdentifier";
import { updateStorage } from "@/services/startApp/updateStorage";
import { checkUpdate } from "@/services/startApp/checkUpdate";
import { OneSignalInit } from "@/services/startApp/oneSignalInit";

import { useAppStore } from "@/store/useAppStore";
import { useUserStore } from "@/store/useUserStore";
import { useConfigStore } from "@/store/useConfigStore";
import { useAuthStore } from "@/store/useAuthStore";

async function saveDataApp() {
  try {
    const { updateApp } = useAppStore.getState();
    const { updateUser } = useUserStore.getState();
    const { updateToken } = useAuthStore.getState();
    const { actions } = useConfigStore.getState();
    const { updateConfigs } = actions;

    const slug = await getAppIdentifier();

    if (!slug) {
      return {
        error: true,
        maintenance: false,
        success: false,
      };
    }

    const { app, user, configs } = await getDataApp(slug);

    document.title = app.name;

    // Old storage
    updateStorage(app, configs);

    // New storage
    localStorage.setItem("slug", app.slug);
    updateApp(app);
    updateConfigs(configs);
    updateUser(user ?? null);

    if (!user) {
      updateToken(null);
      localStorage.removeItem("AUTH_ACCESS_TOKEN");
    }

    if (Capacitor.isNativePlatform()) {
      const enabledOTA = configs.find((item) => item.slug === "enabled.ota");

      if (
        enabledOTA?.value === "true" &&
        app?.version &&
        import.meta.env.VITE_MODE !== "development"
      ) {
        await checkUpdate(app.version);
      }

      if (app?.oneSignalAppId) {
        OneSignalInit({
          slug: app.slug,
          onesignalAppId: app.oneSignalAppId,
          userId: user?.id,
        });
      }
    }

    if (app.colors.primary) {
      setPrimaryColor(app.colors.primary);
    }

    if (app.colors.header) {
      document.documentElement.style.setProperty("--color-header", app.colors.header);
    }

    if (app.colors.textHeader) {
      document.documentElement.style.setProperty("--color-text-header", app.colors.textHeader);
    }

    return {
      maintenance: !!app.maintenance,
      success: true,
      error: false,
    };
  } catch (err) {
    console.error(err);

    if (axios.isAxiosError(err)) {
      if (err.response?.status === 404) {
        return {
          error: true,
          maintenance: false,
          success: false,
        };
      }
    }

    return {
      error: true,
      maintenance: false,
      success: false,
    };
  }
}

export function useStartApp() {
  return useQuery({
    queryKey: ["startApp"],
    queryFn: saveDataApp,
    refetchOnWindowFocus: false,
  });
}
